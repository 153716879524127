import { device } from 'src/styles/breakpoints'
import { brand, laranja } from 'src/styles/colors'
import styled from 'styled-components'

export const Row = styled.div``

export const BoraDespertarSection = styled.section`
  padding: 36px 0 24px 0 !important;
  background-color: ${brand.sand};
  overflow: hidden;

  @media ${device.tablet} {
    padding: 70px 0 !important;
  }

  @media ${device.desktopLG} {
    padding: 113px 0 !important;
  }

  @media ${device.desktopXXL} {
    padding: 148px 0 !important;
  }
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 64px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const Title = styled.h1`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${laranja.terra};
 
  margin-bottom: 12px;
  text-align: center;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;
    text-align: left;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopXXL} {
    font-size: 48px;
    line-height: 53px;
  }
`

export const SubTitle = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${laranja.terra};

  margin-bottom: 16px;
  text-align: center;
  
  @media ${device.tablet} {
    text-align: left;
  }
  
  @media ${device.desktopLG} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${device.desktopXXL} {
    font-size: 24px;
    line-height: 29px;

    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${laranja.terra};

  margin-bottom: 0;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
  }
`
