import { orange, warning } from 'src/styles/newColors'
import styled from 'styled-components'

export const ModalContent = styled.div`
  height: 105vh;
  width: 100%;
  background-color: ${orange.extra};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  h3 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
`

export const PopUpContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  h3 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
`

export const IconWrapper = styled.div`
  background-color: ${warning};
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

export const Content = styled.div`
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
