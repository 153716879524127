import React, { Dispatch, SetStateAction } from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { DepoisAutorizarSection, StepNumber } from './style'

const DataWithStep = [
  {
    position: '1',
    title: 'Acesse o app do FGTS',
    description: 'É muito simples! Primeiro, você acessa o aplicativo do FGTS e adere ao Saque-Aniversário. Aproveite para ler e aceitar os termos e condições.',
  },
  {
    position: '2',
    title: 'Autorize o Inter a consultar o seu FGTS',
    description: 'Entre em “Autorizar bancos a consultar seu FGTS”, escolha a opção “Empréstimo Saque-Aniversário” e <strong>selecione o Inter</strong>.',
  },
  {
    position: '3',
    title: 'Faça sua simulação aqui no Inter',
    description: 'Feito isso, peça aqui no Super App a antecipação do seu Saque-Aniversário. Simples assim!',
  },
]

type dataProps = {
  position: string;
  title: string;
  description: string;
}

interface IDepoisAutorizarProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DepoisAutorizar = ({ setIsOpen }: IDepoisAutorizarProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const WIDTH_MD = 720

  return (
    <DepoisAutorizarSection className='bg-white d-flex position-relative align-items-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-5 pb-md-0 py-md-5 order-1 order-md-2'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/1024-dobra5-fgts/image.webp'
              altDescription=''
              arrayNumbers={[ 320 ]}
              arrayNumbersHeight={[ 310 ]}
            />
          </div>
          <div className='d-flex flex-column col-sm-6 col-xl-5 order-2 order-md-1'>
            <h2 className='fs-24 lh-30 fs-lg-28 lh-lg-32 fs-xl-48 lh-xl-54 text-grayscale--500 fw-500 pb-3 mt-4'>
              Saiba como autorizar o Inter a consultar o seu FGTS
            </h2>

            {DataWithStep.map((item: dataProps) => (
              <div key={item.position} className='d-flex align-items-start pb-3 mb-3'>
                <StepNumber className='fs-14 lh-14 text-white'>{item.position}</StepNumber>
                <div>
                  <p className='text-grayscale--500 fs-14 fs-xl-18 fw-700 lh-19 lh-xl-22 mb-1'>{item.title}</p>
                  <p className='text-grayscale--400 fs-12 fs-xl-14 fw-400 lh-19 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}

            {width > WIDTH_MD ? (
              <button
                onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Saiba como autorizar o Inter a consultar o seu FGTS',
                    element_action: 'click button',
                    element_name: 'Autorizar o Inter',
                  })
                }}
                className='btn text-white btn-orange--extra text-none'
              >
                Autorizar o Inter
              </button>
            ) : (
              <a
                href='https://intergo.app/3c628462'
                target='_blank'
                rel='noreferrer'
                className='btn text-white btn-orange--extra text-none mb-5'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Saiba como autorizar o Inter a consultar o seu FGTS',
                    element_action: 'click button',
                    element_name: 'Autorizar o Inter',
                    redirect_url: 'https://intergo.app/3c628462',
                  })
                }}
              >
                Autorizar o Inter
              </a>
            )}
          </div>
        </div>
      </div>
    </DepoisAutorizarSection>
  )
}

export default DepoisAutorizar
